<template>
  <ResetPasswordPage />
</template>

<script>
import ResetPasswordPage from "@/components/layout/auth/ResetPasswordPage";
export default {
  name: "ForgotPassword",
  components: { ResetPasswordPage }
};
</script>

<style scoped></style>
