<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-container>
          <validation-observer ref="observer">
            <form @submit.prevent="submit">
              <v-row align="center" justify="center">
                <v-col cols="auto">
                  <v-img
                    width="150"
                    :src="require('@/assets/images/cp-app-logo-alt.svg')"
                  />
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <input-email
                      v-model="email"
                      :label="$t('enter_your_email')"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    vid="password"
                    rules="required"
                  >
                    <input-password
                      v-model="password"
                      :label="$t('enter_your_new_password')"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="password_confirmation"
                    rules="required|password_confirmed:password"
                  >
                    <input-password
                      v-model="confirm_password"
                      :label="$t('enter_your_new_password_confirmation')"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="primary"
                    block
                    @click="submit"
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                  >
                    {{ $t("reset_password") }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import InputEmail from "@/components/ui/InputEmail";
import InputPassword from "@/components/ui/InputPassword";
import { mapGetters } from "vuex";
export default {
  name: "ResetPasswordPage",
  components: { InputPassword, InputEmail },
  data() {
    return {
      email: "",
      password: "",
      confirm_password: ""
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    ...mapGetters(["getApiProcessingStatus"])
  },
  methods: {
    submit() {
      let email = this.email;
      let password = this.password;
      let password_confirmation = this.confirm_password;
      let token = this.token;

      this.$store
        .dispatch("AUTH_RESET", {
          email,
          password_confirmation,
          password,
          token
        })
        .then(
          response => {
            if (response.status === 200) {
              this.$router.push("/");
            }
          },
          error => {
            console.error(error);
          }
        )
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped></style>
